var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "mapped-supplier-modal",
      staticClass: "mapped-supplier-modal--component-wrapper",
      attrs: {
        size: "xl",
        "ok-variant": "success",
        "cancel-variant": "light",
        title: _vm.rendTitle,
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        hidden: _vm.handleCloseModal,
        ok: _vm.handleOk,
        cancel: _vm.handleCancel,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _vm.isOpen
        ? [
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { md: "3", lg: "3" } }, [
                  _c("div", { staticClass: "side-container" }, [
                    _c(
                      "div",
                      { staticClass: "options-holder" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.isCenterOnUserLodationLoading,
                              variant: "link",
                            },
                            on: { click: _vm.centerOnUserLocation },
                          },
                          [
                            _vm.isCenterOnUserLodationLoading
                              ? _c("b-spinner", {
                                  attrs: {
                                    variante: "primary",
                                    small: "",
                                    label: "Small Spinner",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(9876678, "Center on my location")
                                ) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "input-holder" },
                      [
                        _c("b-form-input", {
                          attrs: {
                            placeholder: _vm.rendSearchInputPlaceholder,
                          },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        ref: "suppliersListContainer",
                        staticClass: "suppliers-list",
                      },
                      _vm._l(_vm.suppliersMap, function (supplier, i) {
                        return _c(
                          "b-card",
                          {
                            key: i,
                            ref: "supplier-" + supplier.id,
                            refInFor: true,
                            attrs: {
                              "border-variant":
                                _vm.$has(_vm.selected) &&
                                _vm.selected.id === supplier.id
                                  ? "primary"
                                  : "default",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleSupplierClick(supplier)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(supplier.name))])]
                        )
                      }),
                      1
                    ),
                  ]),
                ]),
                _c(
                  "b-col",
                  { attrs: { md: "9", lg: "9" } },
                  [
                    _vm.$has(_vm.suppliers)
                      ? _c(
                          "gmap-map",
                          {
                            ref: "gmap",
                            staticStyle: { width: "100%", height: "500px" },
                            attrs: { center: _vm.mapCenter, zoom: _vm.mapZoom },
                          },
                          _vm._l(_vm.suppliersMap, function (supplier, i) {
                            return _c("gmap-marker", {
                              key: i,
                              attrs: {
                                clickable: "",
                                position: {
                                  lat: supplier.address.lat,
                                  lng: supplier.address.lng,
                                },
                                zIndex:
                                  _vm.selected &&
                                  _vm.selected.id === supplier.id
                                    ? _vm.selectedZIndex
                                    : _vm.defaultZIndex,
                                icon: _vm.getIcon(supplier),
                                draggable: false,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleMarkerClick(supplier)
                                },
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }