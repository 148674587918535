<template>
	<b-modal
		v-model="isOpen"
		class="mapped-supplier-modal--component-wrapper"
		ref="mapped-supplier-modal"
		size="xl"
		ok-variant="success"
		cancel-variant="light"
		:title="rendTitle"
		@hidden="handleCloseModal"
		@ok="handleOk"
		@cancel="handleCancel"
		modal-class="mui-animation"
		:fade="false"
	>
		<template v-if="isOpen">
			<b-row>
				<b-col md="3" lg="3">
					<div class="side-container">
						<div class="options-holder">
							<b-button :disabled="isCenterOnUserLodationLoading" variant="link" @click="centerOnUserLocation">
								<b-spinner v-if="isCenterOnUserLodationLoading" variante="primary" small label="Small Spinner" />
								{{ FormMSG(9876678, 'Center on my location') }}
							</b-button>
						</div>
						<div class="input-holder">
							<b-form-input v-model="search" :placeholder="rendSearchInputPlaceholder" />
						</div>

						<div class="suppliers-list" ref="suppliersListContainer">
							<b-card
								v-for="(supplier, i) in suppliersMap"
								:key="i"
								:ref="'supplier-' + supplier.id"
								:border-variant="$has(selected) && selected.id === supplier.id ? 'primary' : 'default'"
								@click="handleSupplierClick(supplier)"
							>
								<span>{{ supplier.name }}</span>
							</b-card>
						</div>
					</div>
				</b-col>

				<b-col md="9" lg="9">
					<gmap-map ref="gmap" v-if="$has(suppliers)" :center="mapCenter" :zoom="mapZoom" style="width: 100%; height: 500px">
						<gmap-marker
							v-for="(supplier, i) in suppliersMap"
							clickable
							:key="i"
							:position="{ lat: supplier.address.lat, lng: supplier.address.lng }"
							:zIndex="selected && selected.id === supplier.id ? selectedZIndex : defaultZIndex"
							:icon="getIcon(supplier)"
							:draggable="false"
							@click="handleMarkerClick(supplier)"
						/>
						<!-- :icon="selected && selected.id === supplier.id ? { url: 'http://maps.google.com/mapfiles/ms/icons/' + selectedMarkerColor + '-dot.png' } : null" -->
					</gmap-map>
				</b-col>
			</b-row>
		</template>
	</b-modal>
</template>

<script>
import { isNil, getObjectFromValue } from '~utils';
import mapProps from '@/shared/vuePropsMapper';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'MappedSupplierModalComponent',
	mixins: [languageMessages],
	props: {
		...mapProps(['title', 'placeholder'], {
			type: String,
			required: false,
			default: null
		}),
		selection: {
			type: Object,
			required: false,
			default: null
		},
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		currsearch: {
			type: String,
			required: false,
			default: ''
		},
		suppliers: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {
			mapCenter: { lat: 48.8566, lng: 2.3522 }, // Default center (Paris, France)
			mapZoom: 6, // default zoom
			selectedMarkerColor: 'green',
			defaultMarkerColor: 'red',
			search: this.currsearch,
			selected: this.selection || null,
			defaultZIndex: 1, // Default zIndex for markers
			selectedZIndex: 1000, // zIndex for the selected marker
			isCenterOnUserLodationLoading: false
		};
	},
	computed: {
		isOpen: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.value;
			},
			/**
			 * @param {Boolean} status
			 */
			set(status) {
				this.$emit('input', status);
			}
		},

		rendTitle() {
			return isNil(this.title) ? this.FormMSG(8765786, 'Find your supplier') : this.title;
		},

		suppliersMap() {
			return this.suppliers
				.filter(
					(s) =>
						s.address.lat && s.address.lng && s.address.lat !== 0 && s.address.lng !== 0 && s.name.toLowerCase().includes(this.search.toLowerCase())
				)
				.map((sup) => {
					return {
						id: sup.id,
						name: sup.name,
						address: sup.address
					};
				});
		},

		rendSearchInputPlaceholder() {
			return isNil(this.placeholder) ? this.FormMSG(123, "Enter a supplier's name") : this.placeholder;
		}
	},
	watch: {
		/**
		 * @param {Boolean} newVal
		 */
		isOpen(newVal) {
			if (!newVal) return;

			this.$nextTick(async () => {
				if (!isNil(this.selection)) {
					this.initSelection();
				} else {
					const userLocationAllowed = await this.checkUserLocationPermission();
					if (userLocationAllowed) {
						this.centerOnUserLocation();
					} else {
						this.centerOnAverageLocation();
					}
				}
			});
		}
	},
	// watch: {
	//   isOpen(newVal) {
	//     if (!newVal) return;
	//     return isNil(this.selection) && !isNil(this.selected)
	//       ? this.centerOnAverageLocation()
	//       : this.initSelection();
	//
	//     this.$nextTick(() => {
	//       if (!isNil(this.selection) && !isNil(this.selected)) return;
	//       this.centerOnUserLocation();
	//     });
	//   },
	// },
	// mounted() {
	//   if (!this.isOpen) return
	//   this.$nextTick(() => {
	//     this.centerOnAverageLocation();
	//     console.log({ GMAP: this.$refs.gmap })
	//     if (isNil(this.$refs.gmap)) return
	//     this.$refs.gmap.$mapPromise.then(() => {
	//       this.fitMapToBounds();
	//     });
	//   })
	// },
	methods: {
		initSelection() {
			if (isNil(this.selection)) return;
			const _s = getObjectFromValue(this.suppliersMap, 'id', this.selection.code);
			if (isNil(_s) || isNil(_s.address)) {
				this.centerOnAverageLocation();
			} else {
				this.handleSupplierClick(_s);
			}
		},

		fitMapToBounds() {
			if (!this.suppliers || this.suppliers.length === 0) return;

			const bounds = new google.maps.LatLngBounds();

			this.suppliers.forEach((supplier) => {
				bounds.extend(new google.maps.LatLng(supplier.address.lat, supplier.address.lng));
			});

			this.$nextTick(() => {
				if (isNil(this.$refs.gmap) || isNil(this.$refs.gmap.$mapObject)) return;
				this.$refs.gmap.$mapObject.fitBounds(bounds);
			});
		},

		centerOnAverageLocation() {
			if (!this.suppliersMap || this.suppliersMap.length === 0) return;

			let totalLat = 0;
			let totalLng = 0;

			this.suppliersMap.forEach((supplier) => {
				totalLat += supplier.address.lat;
				totalLng += supplier.address.lng;
			});

			const averageLat = totalLat / this.suppliersMap.length;
			const averageLng = totalLng / this.suppliersMap.length;

			this.mapCenter = { lat: averageLat, lng: averageLng };
			this.$nextTick(() => this.fitMapToBounds());
			this.mapZoom = 10;
		},

		fetchUserLocation() {
			if (!navigator.geolocation) return;
			this.isCenterOnUserLodationLoading = true;

			navigator.geolocation.getCurrentPosition(
				(position) => {
					this.mapCenter = {
						lat: position.coords.latitude,
						lng: position.coords.longitude
					};
					this.isCenterOnUserLodationLoading = false;
				},
				(error) => {
					console.error('Error getting user location:', error);
					this.isCenterOnUserLodationLoading = false;
				},
				{
					timeout: 5000
				}
			);
			this.mapZoom = 10;
		},

		async centerOnUserLocation() {
			try {
				const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

				if (permissionStatus.state === 'granted') {
					this.fetchUserLocation();
				} else if (permissionStatus.state === 'prompt') {
					permissionStatus.onchange = () => {
						if (permissionStatus.state === 'granted') {
							this.fetchUserLocation();
						}
					};
				} else {
					throw new Error('Geolocation permission not granted.');
				}
			} catch (error) {
				console.error('Error requesting geolocation permission:', error);
			}
		},

		async checkUserLocationPermission() {
			try {
				const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
				return permissionStatus.state === 'granted' || permissionStatus.state === 'prompt';
			} catch (error) {
				console.error('Error requesting geolocation permission:', error);
				return false;
			}
		},

		getIcon(supplier) {
			return this.selected && this.selected.id === supplier.id
				? {
						url: 'http://maps.google.com/mapfiles/ms/icons/' + this.selectedMarkerColor + '-dot.png',
						scaledSize: new google.maps.Size(48, 48)
				  }
				: {
						url: 'http://maps.google.com/mapfiles/ms/icons/' + this.defaultMarkerColor + '-dot.png',
						scaledSize: new google.maps.Size(32, 32)
				  };
		},

		scrollToSelectedSupplier() {
			if (isNil(this.selected)) return;

			const selectedRef = this.$refs['supplier-' + this.selected.id];

			if (!selectedRef || !selectedRef[0]) return;

			const listElement = this.$refs.suppliersListContainer;
			const supplierElement = isNil(selectedRef[0]) ? selectedRef : selectedRef[0];

			listElement.scrollTop = supplierElement.offsetTop - listElement.offsetTop;
		},

		handleCloseModal() {
			this.$emit('input', false);
		},

		handleMarkerClick(supplier) {
			this.selected = supplier;
			this.scrollToSelectedSupplier();
		},

		handleSupplierClick(supplier) {
			this.selected = supplier;
			this.mapCenter = {
				lat: supplier.address.lat,
				lng: supplier.address.lng
			};
			this.mapZoom = 15;
		},

		handleCancel() {
			this.isOpen = false;
		},
		handleOk() {
			this.$emit('selected', this.selected);
			this.isOpen = false;
		}
	}
};
</script>

<style lang="scss" scope>
.side-container {
	.input-holder {
		width: 100%;
		height: 48px;
	}
	.suppliers-list {
		width: 100%;
		height: calc(500px - 48px);
		overflow-x: scroll;

		.card-body {
			padding: 0;
		}
	}

	.options-holder {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		bbutton {
			padding-right: 0;
		}
	}
}
</style>
